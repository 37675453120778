<template>
	<div>
		<div class="pdf-preview-wrapper w-100">
			<v-layout class="header-top w-100">
				<v-flex class="file-name">
					<div>
						<v-icon class="red--text" style="width: 54px; font-size: 34px">mdi-file-pdf-outline</v-icon>
					</div>
					<div class="name mr-4">
						<span>NLKMi66C3WtOslfQDT1F5UPJegqM1jDF14ahLR5m.pdf</span>
						<div class="size">91KB</div>
					</div>
					<div>
						<v-tooltip bottom content-class="custom-bottom-tooltip tooltip-text">
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-on="on" v-bind="attrs" class="rename-btn ml-2" color="blue" link
									>mdi-pencil</v-icon
								>
							</template>
							<span class="">Rename</span>
						</v-tooltip>
						<v-tooltip bottom content-class="custom-bottom-tooltip tooltip-text">
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-on="on" v-bind="attrs" class="rename-btn ml-2" color="blue" link
									>mdi-lock-open-outline</v-icon
								>
							</template>
							<span class="">Lock</span>
						</v-tooltip>
					</div>
				</v-flex>
				<v-spacer></v-spacer>
				<v-flex class="file-actions d-flex justify-content-end">
					<div class="btn icon">
						<img src="/media/pdf/printer.svg" alt="" width="24px" height="24px" />
					</div>
					<div class="btn icon">
						<img src="/media/pdf/share.svg" alt="" width="24px" height="24px" />
					</div>
					<div class="btn txt">
						<img src="/media/pdf/upload.svg" alt="" width="24px" height="24px" />
						<span>Export</span>
					</div>
					<div class="btn txt download">
						<img src="/media/pdf/download.svg" alt="" width="24px" height="24px" />
						<span>Download</span>
					</div>
				</v-flex>
			</v-layout>
			<v-layout class="d-flex pdf-header">
				<div class="header-lb d-flex">
					<v-tooltip right content-class="custom-right-tooltip tooltip-text">
						<template v-slot:activator="{ on, attrs }">
							<div v-on="on" v-bind="attrs" class="document-view-icon svg-icon">
								<img src="/media/pdf/document-view.svg" />
							</div>
						</template>
						<span class="">Document View</span>
					</v-tooltip>
					<v-tooltip top content-class="custom-top-tooltip tooltip-text">
						<template v-slot:activator="{ on, attrs }">
							<div v-on="on" v-bind="attrs" class="page-view-icon svg-icon">
								<img src="/media/pdf/page-view.svg" />
							</div>
						</template>
						<span class="">Page View</span>
					</v-tooltip>
					<v-tooltip top content-class="custom-top-tooltip tooltip-text">
						<template v-slot:activator="{ on, attrs }">
							<div v-on="on" v-bind="attrs" class="thumbnail-view-icon svg-icon">
								<img src="/media/pdf/thumbnail-view.svg" />
							</div>
						</template>
						<span class="">Thumbnail View</span>
					</v-tooltip>
				</div>
				<v-spacer></v-spacer>
				<div class="header-mb d-flex">
					<v-tooltip top content-class="custom-top-tooltip tooltip-text">
						<template v-slot:activator="{ on, attrs }">
							<div v-on="on" v-bind="attrs" class="svg-icon active">
								<img src="/media/pdf/pointer.svg" />
							</div>
						</template>
						<span class="">Select (V)</span>
					</v-tooltip>
					<!-- //PENCIL BTN -->
					<v-menu
						bottom
						offset-y
						:close-on-content-click="false"
						content-class="btns-submenu-wrapper"
						max-width="200px"
					>
						<template v-slot:activator="{ on, attrs }">
							<div v-on="on" v-bind="attrs" class="ml-2">
								<v-tooltip top content-class="custom-top-tooltip tooltip-text">
									<template v-slot:activator="{ on, attrs }">
										<div v-on="on" v-bind="attrs" class="svg-icon"><img src="/media/pdf/pencil.svg" /></div>
									</template>
									<span class="">Pencil (P)</span>
								</v-tooltip>
							</div>
						</template>
						<div class="btns-submenu">
							<v-tooltip top content-class="custom-top-tooltip tooltip-text">
								<template v-slot:activator="{ on, tooltips }">
									<div v-on="on" v-bind="tooltips" class="row-1 d-flex">
										<div class="submenu-icon">
											<img src="/media/pdf/thickness.svg" alt="" />
										</div>
										<div class="px-1" style="width: 160px">
											<v-slider
												v-model="thickness_slider"
												class="align-center"
												:max="thickness_max"
												:min="thickness_min"
												hide-details
											>
												<template v-slot:append>
													<div style="min-width: 30px">{{ thickness_slider }}px</div>
												</template>
											</v-slider>
										</div>
									</div>
								</template>
								<span class="">Thickness</span>
							</v-tooltip>
							<div class="seprater"></div>
							<v-tooltip top content-class="custom-top-tooltip tooltip-text">
								<template v-slot:activator="{ on, tooltips }">
									<div v-on="on" v-bind="tooltips" class="row-1 d-flex">
										<div class="submenu-icon">
											<img src="/media/pdf/opacity.svg" alt="" />
										</div>
										<div class="px-1" style="width: 160px">
											<v-slider
												v-model="opacity_slider"
												class="align-center"
												:max="opacity_max"
												:min="opacity_min"
												hide-details
											>
												<template v-slot:append>
													<div style="min-width: 30px">{{ opacity_slider }}%</div>
												</template>
											</v-slider>
										</div>
									</div>
								</template>
								<span class="">Opacity</span>
							</v-tooltip>
							<div class="seprater"></div>
							<div class="color-section d-flex flex-wrap">
								<div class="color-box">
									<div class="white"></div>
								</div>
								<div class="color-box">
									<div class="black"></div>
								</div>
								<div class="color-box">
									<div class="green"></div>
								</div>
								<div class="color-box">
									<div class="red"></div>
								</div>
								<div class="color-box">
									<div class="blue"></div>
								</div>
								<div class="color-box">
									<div class="orange"></div>
								</div>
								<div class="color-box">
									<div class="blue"></div>
								</div>
							</div>
							<div class="seprater"></div>
							<div class="d-flex">
								<div class="submenu-icon mx-0">
									<img src="/media/pdf/plus.svg" alt="" />
								</div>
								<div class="blue--text cursor-pointer my-auto ml-1" v-on:click="color_drawer = true">
									Add custome color
								</div>
							</div>
						</div>
					</v-menu>
					<!-- HIGHLIGHTER BTN -->
					<v-menu
						bottom
						offset-y
						:close-on-content-click="false"
						content-class="btns-submenu-wrapper"
						max-width="200px"
					>
						<template v-slot:activator="{ on, attrs }">
							<div v-on="on" v-bind="attrs" class="ml-2">
								<v-tooltip top content-class="custom-top-tooltip tooltip-text">
									<template v-slot:activator="{ on, attrs }">
										<div v-on="on" v-bind="attrs" class="svg-icon">
											<img src="/media/pdf/highlighter.svg" />
										</div>
									</template>
									<span class="">Highlighter (H)</span>
								</v-tooltip>
							</div>
						</template>
						<div class="btns-submenu">
							<v-tooltip top content-class="custom-top-tooltip tooltip-text">
								<template v-slot:activator="{ on, tooltips }">
									<div v-on="on" v-bind="tooltips" class="row-1 d-flex">
										<div class="submenu-icon">
											<img src="/media/pdf/thickness.svg" alt="" />
										</div>
										<div class="px-1" style="width: 160px">
											<v-slider
												v-model="thickness_slider"
												class="align-center"
												:max="thickness_max"
												:min="thickness_min"
												hide-details
											>
												<template v-slot:append>
													<div style="min-width: 30px">{{ thickness_slider }}px</div>
												</template>
											</v-slider>
										</div>
									</div>
								</template>
								<span class="">Thickness</span>
							</v-tooltip>
							<div class="seprater"></div>
							<v-tooltip top content-class="custom-top-tooltip tooltip-text">
								<template v-slot:activator="{ on, tooltips }">
									<div v-on="on" v-bind="tooltips" class="row-1 d-flex">
										<div class="submenu-icon">
											<img src="/media/pdf/opacity.svg" alt="" />
										</div>
										<div class="px-1" style="width: 160px">
											<v-slider
												v-model="opacity_slider"
												class="align-center"
												:max="opacity_max"
												:min="opacity_min"
												hide-details
											>
												<template v-slot:append>
													<div style="min-width: 30px">{{ opacity_slider }}%</div>
												</template>
											</v-slider>
										</div>
									</div>
								</template>
								<span class="">Opacity</span>
							</v-tooltip>
							<div class="seprater"></div>
							<div class="color-section d-flex flex-wrap">
								<div class="color-box">
									<div class="white"></div>
								</div>
								<div class="color-box">
									<div class="black"></div>
								</div>
								<div class="color-box">
									<div class="green"></div>
								</div>
								<div class="color-box">
									<div class="red"></div>
								</div>
								<div class="color-box">
									<div class="blue"></div>
								</div>
								<div class="color-box">
									<div class="orange"></div>
								</div>
								<div class="color-box">
									<div class="blue"></div>
								</div>
							</div>
							<div class="seprater"></div>
							<div class="d-flex">
								<div class="submenu-icon mx-0">
									<img src="/media/pdf/plus.svg" alt="" />
								</div>
								<div class="blue--text cursor-pointer my-auto ml-1" v-on:click="color_drawer = true">
									Add custome color
								</div>
							</div>
						</div>
					</v-menu>
					<!-- ERASER BTN -->
					<v-menu
						bottom
						offset-y
						:close-on-content-click="false"
						content-class="btns-submenu-wrapper"
						min-width="200px"
						max-width="200px"
					>
						<template v-slot:activator="{ on, attrs }">
							<div v-on="on" v-bind="attrs" class="ml-2">
								<v-tooltip top content-class="custom-top-tooltip tooltip-text">
									<template v-slot:activator="{ on, attrs }">
										<div v-on="on" v-bind="attrs" class="eraser-icon svg-icon">
											<img src="/media/pdf/eraser.svg" />
										</div>
									</template>
									<span class="">Eraser (E)</span>
								</v-tooltip>
							</div>
						</template>
						<div class="btns-submenu">
							<v-tooltip top content-class="custom-top-tooltip tooltip-text">
								<template v-slot:activator="{ on, tooltips }">
									<div v-on="on" v-bind="tooltips" class="row-1 d-flex">
										<div class="submenu-icon">
											<img src="/media/pdf/thickness.svg" alt="" />
										</div>
										<div class="px-1" style="width: 160px">
											<v-slider
												v-model="thickness_slider"
												class="align-center"
												:max="thickness_max"
												:min="thickness_min"
												hide-details
											>
												<template v-slot:append>
													<div style="min-width: 30px">{{ thickness_slider }}px</div>
												</template>
											</v-slider>
										</div>
									</div>
								</template>
								<span class="">Thickness</span>
							</v-tooltip>
						</div>
					</v-menu>
					<!-- Text highlighter -->
					<v-menu
						bottom
						offset-y
						:close-on-content-click="false"
						content-class="btns-submenu-wrapper"
						max-width="200px"
						min-width="200px"
					>
						<template v-slot:activator="{ on, attrs }">
							<div v-on="on" v-bind="attrs" class="ml-2">
								<v-tooltip top content-class="custom-top-tooltip tooltip-text">
									<template v-slot:activator="{ on, attrs }">
										<div v-on="on" v-bind="attrs" class="svg-icon">
											<img src="/media/pdf/text-highlight.svg" />
										</div>
									</template>
									<span class="">Highlight Text (W)</span>
								</v-tooltip>
							</div>
						</template>
						<div class="btns-submenu">
							<v-tooltip top content-class="custom-top-tooltip tooltip-text">
								<template v-slot:activator="{ on, tooltips }">
									<div v-on="on" v-bind="tooltips" class="row-1 d-flex">
										<div class="submenu-icon">
											<img src="/media/pdf/opacity.svg" alt="" />
										</div>
										<div class="px-1" style="width: 160px">
											<v-slider
												v-model="opacity_slider"
												class="align-center"
												:max="opacity_max"
												:min="opacity_min"
												hide-details
											>
												<template v-slot:append>
													<div style="min-width: 30px">{{ opacity_slider }}%</div>
												</template>
											</v-slider>
										</div>
									</div>
								</template>
								<span class="">Opacity</span>
							</v-tooltip>
							<div class="seprater"></div>
							<div class="color-section d-flex flex-wrap">
								<div class="color-box">
									<div class="white"></div>
								</div>
								<div class="color-box">
									<div class="black"></div>
								</div>
								<div class="color-box">
									<div class="green"></div>
								</div>
								<div class="color-box">
									<div class="red"></div>
								</div>
								<div class="color-box">
									<div class="blue"></div>
								</div>
								<div class="color-box">
									<div class="orange"></div>
								</div>
								<div class="color-box">
									<div class="blue"></div>
								</div>
							</div>
							<div class="seprater"></div>
							<div class="d-flex">
								<div class="submenu-icon mx-0">
									<img src="/media/pdf/plus.svg" alt="" />
								</div>
								<div class="blue--text cursor-pointer my-auto ml-1" v-on:click="color_drawer = true">
									Add custome color
								</div>
							</div>
						</div>
					</v-menu>
					<!-- Text -->
					<v-menu bottom offset-y :close-on-content-click="false" content-class="btns-submenu-wrapper">
						<template v-slot:activator="{ on, attrs }">
							<div v-on="on" v-bind="attrs" class="ml-2">
								<v-tooltip top content-class="custom-top-tooltip tooltip-text">
									<template v-slot:activator="{ on, attrs }">
										<div v-on="on" v-bind="attrs" class="svg-icon"><img src="/media/pdf/text.svg" /></div>
									</template>
									<span class="">Text (T)</span>
								</v-tooltip>
							</div>
						</template>
						<div class="btns-submenu">
							<div class="text-submenu d-flex">
								<!-- start -->
								<v-menu
									bottom
									offset-y
									:close-on-content-click="false"
									content-class="child-submenu-wrapper"
									min-width="200px"
									max-width="200px"
								>
									<template v-slot:activator="{ on, attrs }">
										<div class="font-family" v-on="on" v-bind="attrs">
											<div>Times New Roman</div>
										</div>
									</template>
									<div class="select-font">
										<v-autocomplete hide-details outlined depressed :items="font_list" v-model="sdsdfg">
										</v-autocomplete>
									</div>
								</v-menu>
								<!-- end -->
								<!-- submenu color -->
								<v-menu
									bottom
									offset-y
									:close-on-content-click="false"
									content-class="btns-submenu-wrapper"
									max-width="200px"
									min-width="200px"
								>
									<template v-slot:activator="{ on, attrs }">
										<div v-on="on" v-bind="attrs" class="ml-2">
											<v-tooltip top content-class="custom-top-tooltip tooltip-text">
												<template v-slot:activator="{ on, tooltips }">
													<div v-on="on" v-bind="tooltips" class="row-1 d-flex mr-2">
														<div class="text-color-box" style="height: 40px; padding: 10px">
															<div class="red"></div>
														</div>
													</div>
												</template>
												<span class="">Color</span>
											</v-tooltip>
										</div>
									</template>
									<div class="btns-submenu">
										<v-tooltip top content-class="custom-top-tooltip tooltip-text">
											<template v-slot:activator="{ on, tooltips }">
												<div v-on="on" v-bind="tooltips" class="row-1 d-flex">
													<div class="submenu-icon">
														<img src="/media/pdf/opacity.svg" alt="" />
													</div>
													<div class="px-1" style="width: 160px">
														<v-slider
															v-model="opacity_slider"
															class="align-center"
															:max="opacity_max"
															:min="opacity_min"
															hide-details
														>
															<template v-slot:append>
																<div style="min-width: 30px">{{ opacity_slider }}%</div>
															</template>
														</v-slider>
													</div>
												</div>
											</template>
											<span class="">Opacity</span>
										</v-tooltip>
										<div class="seprater"></div>
										<div class="color-section d-flex flex-wrap">
											<div class="color-box">
												<div class="white"></div>
											</div>
											<div class="color-box">
												<div class="black"></div>
											</div>
											<div class="color-box">
												<div class="green"></div>
											</div>
											<div class="color-box">
												<div class="red"></div>
											</div>
											<div class="color-box">
												<div class="blue"></div>
											</div>
											<div class="color-box">
												<div class="orange"></div>
											</div>
											<div class="color-box">
												<div class="blue"></div>
											</div>
										</div>
										<div class="seprater"></div>
										<div class="d-flex">
											<div class="submenu-icon mx-0">
												<img src="/media/pdf/plus.svg" alt="" />
											</div>
											<div class="blue--text cursor-pointer my-auto ml-1" v-on:click="color_drawer = true">
												Add custome color
											</div>
										</div>
									</div>
								</v-menu>
								<!-- submenu color end -->
								<v-tooltip top content-class="custom-top-tooltip tooltip-text">
									<template v-slot:activator="{ on, tooltips }">
										<div v-on="on" v-bind="tooltips" class="row-1 d-flex pr-1">
											<input type="number" class="input-size" value="12" />
										</div>
									</template>
									<span class="">Size</span>
								</v-tooltip>

								<!-- Align menu -->
								<v-menu
									bottom
									offset-y
									left
									:close-on-content-click="false"
									content-class="child-submenu-wrapper"
								>
									<template v-slot:activator="{ on, attrs }">
										<div v-on="on" v-bind="attrs" class="ml-2">
											<v-tooltip top content-class="custom-top-tooltip tooltip-text">
												<template v-slot:activator="{ on, tooltips }">
													<div v-on="on" v-bind="tooltips" class="btn-icon">
														<img src="/media/pdf/align-left.svg" />
													</div>
												</template>
												<span class="">Alignment</span>
											</v-tooltip>
										</div>
									</template>
									<div class="child-submenu d-flex p-2">
										<v-tooltip top content-class="custom-top-tooltip tooltip-text">
											<template v-slot:activator="{ on, tooltips }">
												<div v-on="on" v-bind="tooltips" class="btn-icon">
													<img src="/media/pdf/align-left.svg" />
												</div>
											</template>
											<span class="">Align Left</span>
										</v-tooltip>
										<v-tooltip top content-class="custom-top-tooltip tooltip-text">
											<template v-slot:activator="{ on, tooltips }">
												<div v-on="on" v-bind="tooltips" class="btn-icon">
													<img src="/media/pdf/align-left.svg" />
												</div>
											</template>
											<span class="">Align Center</span>
										</v-tooltip>
										<v-tooltip top content-class="custom-top-tooltip tooltip-text">
											<template v-slot:activator="{ on, tooltips }">
												<div v-on="on" v-bind="tooltips" class="btn-icon">
													<img src="/media/pdf/align-left.svg" />
												</div>
											</template>
											<span class="">Align Right</span>
										</v-tooltip>
									</div>
								</v-menu>
							</div>
						</div>
					</v-menu>
					<!-- Shapes -->
					<v-menu
						bottom
						offset-y
						:close-on-content-click="false"
						content-class="btns-submenu-wrapper"
						min-width="200px"
						max-width="200px"
					>
						<template v-slot:activator="{ on, attrs }">
							<div v-on="on" v-bind="attrs" class="ml-2">
								<v-tooltip top content-class="custom-top-tooltip tooltip-text">
									<template v-slot:activator="{ on, attrs }">
										<div v-on="on" v-bind="attrs" class="svg-icon"><img src="/media/pdf/shape.svg" /></div>
									</template>
									<span class="">Shape</span>
								</v-tooltip>
							</div>
						</template>
						<div class="btns-submenu">
							<div class="spapes-wrapper">
								<div class="d-flex py-2">
									<div class="menu-icon mr-2">
										<img src="/media/pdf/shape.svg" alt="" />
									</div>
									<div>Rectangle</div>
								</div>
								<div class="d-flex py-2">
									<div class="menu-icon mr-2">
										<img src="/media/pdf/ellipse.svg" alt="" />
									</div>
									<div>Ellipse</div>
								</div>
								<div class="d-flex py-2">
									<div class="menu-icon mr-2">
										<img src="/media/pdf/line.svg" alt="" />
									</div>
									<div>Line</div>
								</div>
								<div class="d-flex py-2">
									<div class="menu-icon mr-2">
										<img src="/media/pdf/arrow.svg" alt="" />
									</div>
									<div>Arrow</div>
								</div>
							</div>
						</div>
					</v-menu>

					<!-- Insert image -->
					<v-tooltip top content-class="custom-top-tooltip tooltip-text">
						<template v-slot:activator="{ on, attrs }">
							<div v-on="on" v-bind="attrs" class="svg-icon">
								<img src="/media/pdf/insert-image.svg" />
							</div>
						</template>
						<span class="">Insert Image (I)</span>
					</v-tooltip>
				</div>
				<v-spacer></v-spacer>
				<div class="header-rb d-flex">
					<v-tooltip top content-class="custom-top-tooltip tooltip-text">
						<template v-slot:activator="{ on, attrs }">
							<div v-on="on" v-bind="attrs" class="undo-icon svg-icon">
								<img src="/media/pdf/undo.svg" />
							</div>
						</template>
						<span class="">Undo</span>
					</v-tooltip>
					<v-tooltip top content-class="custom-top-tooltip tooltip-text">
						<template v-slot:activator="{ on, attrs }">
							<div v-on="on" v-bind="attrs" class="svg-icon"><img src="/media/pdf/redo.svg" /></div>
						</template>
						<span class="">Redo</span>
					</v-tooltip>
					<div class="svg-icon"><img src="/media/pdf/search.svg" /></div>
				</div>
			</v-layout>
			<v-layout class="pdf-body">
				<v-flex class="sidebar">
					<div class="sidebar-wrapper">
						<div class="page-card">
							<div class="page-card-img">
								<img src="media/pdf/preview-img.jpg" alt="" />
							</div>
						</div>
						<div class="insert-cart">
							<v-icon color="blue darken-4 pb-3" style="font-size: 50px">mdi-file-plus-outline</v-icon>
							<div class="txt-blue">Insert</div>
							<div class="txt">or drag & drop</div>
						</div>
					</div>
				</v-flex>
				<v-flex class="content">
					<div class="content-wrapper">
						<div class="content-inner">
							<img src="/media/pdf/preview-img.jpg" alt="" />
						</div>
						<div class="pdf-pagination">
							<div class="left">
								<div class="btns">
									<v-tooltip top content-class="custom-top-tooltip tooltip-text">
										<template v-slot:activator="{ on, attrs }">
											<div v-on="on" v-bind="attrs" class="prev">
												<img src="/media/pdf/menu-left.svg" alt="" />
											</div>
										</template>
										<span class="">Previous Page</span>
									</v-tooltip>
									<div class="number">
										<span>1</span>
									</div>
									<v-tooltip top content-class="custom-top-tooltip tooltip-text">
										<template v-slot:activator="{ on, attrs }">
											<div v-on="on" v-bind="attrs" class="next">
												<img src="/media/pdf/menu-left.svg" alt="" />
											</div>
										</template>
										<span class="">Next Page</span>
									</v-tooltip>
								</div>
								<div class="txt">
									<span>of 1</span>
								</div>
							</div>
							<div class="right">
								<v-tooltip top content-class="custom-top-tooltip tooltip-text">
									<template v-slot:activator="{ on, attrs }">
										<div v-on="on" v-bind="attrs" class="fit-to-width">
											<img src="/media/pdf/fit-to-width.svg" alt="" />
										</div>
									</template>
									<span class="">Fit to width</span>
								</v-tooltip>
								<v-tooltip top content-class="custom-top-tooltip tooltip-text">
									<template v-slot:activator="{ on, attrs }">
										<div v-on="on" v-bind="attrs" class="zoom-out">
											<img src="/media/pdf/zoom-out.svg" alt="" />
										</div>
									</template>
									<span class="">Zoom out</span>
								</v-tooltip>
								<v-tooltip top content-class="custom-top-tooltip tooltip-text">
									<template v-slot:activator="{ on, attrs }">
										<div v-on="on" v-bind="attrs" class="zoom-in">
											<img src="/media/pdf/zoom-in.svg" alt="" />
										</div>
									</template>
									<span class="">Zoom In</span>
								</v-tooltip>
							</div>
						</div>
					</div>
				</v-flex>
			</v-layout>
		</div>
		<ColorPicker :drawer="color_drawer" v-on:close="color_drawer = false"></ColorPicker>
	</div>
</template>
<script>
import ColorPicker from "@/view/components/ColorPicker";
export default {
	name: "users",
	components: {
		ColorPicker,
	},
	data() {
		return {
			color_drawer: false,
			selectedItem: 0,
			thickness_min: 1,
			thickness_max: 50,
			thickness_slider: 10,
			opacity_min: 0,
			opacity_max: 100,
			opacity_slider: 100,
			headers: [
				{
					text: "Image",
					value: "avatar",
					align: "start",
					sortable: false,
				},
				{
					text: "Name",
					align: "start",
					sortable: false,
					value: "name",
				},
				{ text: "Email", value: "email", sortable: false },
				{ text: "Joining", value: "joining", sortable: false },
				{ text: "Role", value: "role", sortable: false },
			],
			userLists: [
				{
					name: "Bthrust",
					email: "*******",
					joining: "12 Jan",
					role: "developer",
					avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
				},
				{
					name: "Bthrust",
					email: "*******",
					joining: "12 Jan",
					role: "developer",
					avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
				},
				{
					name: "Bthrust",
					email: "*******",
					joining: "12 Jan",
					role: "developer",
					avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
				},
				{
					name: "Bthrust",
					email: "*******",
					joining: "12 Jan",
					role: "developer",
					avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
				},
			],
		};
	},
};
</script>
<style>
.user-list-table .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
	text-transform: uppercase;
	height: 35px;
	color: #0d47a1 !important;
}
.user-list-table .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.user-list-table .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
	font-size: 13px;
}
.user-list-table table tbody tr td:not(:last-child),
.user-list-table table thead tr th:not(:last-child) {
	border-right: 1px solid #e2e5ec !important;
}
.user-list-topbar .primary--text {
	font-size: 18px !important;
	font-weight: 600 !important;
	width: 100% !important;
	color: #0d47a1 !important;
	letter-spacing: 0px !important;
	line-height: 1.11em !important;
	text-transform: uppercase !important;
}
</style>
