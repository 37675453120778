<template>
	<v-navigation-drawer v-model="drawer" fixed right stateless width="350" style="z-index: 99">
		<div class="header px-3 d-flex">
			<div class="drawer-title">Color Picker</div>
			<v-icon class="close white--text" v-on:click="$emit('close')"> mdi-close </v-icon>
		</div>
		<div class="body px-3">
			<v-color-picker class="ma-2" canvas-height="300"></v-color-picker>
		</div>
	</v-navigation-drawer>
</template>
<script>
export default {
	name: "color-picker",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
<style scoped>
.header {
	background: #f57c00;
	color: #fff;
}
.drawer-title {
	font-size: 1.5rem;
	margin: auto 0;
}
</style>
